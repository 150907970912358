<template>
    <div>
      <v-layout row wrap align-center class="content-header-2col">
            <v-flex xs6 class="content-header-2col-title">
                <base-page-title v-bind:page-title="pageTitle"></base-page-title>
            </v-flex>
          <v-spacer/>
          <ul class="legend">
            <li><span class="red"></span> {{ $i18n.t('Blocked customer LCCA') }}</li>
          </ul>
          <v-btn
          color="primary"
          @click="updateIndexing"
          v-if="isAdminforIndexing"
          >{{  $i18n.t('update customer')  }}
          </v-btn>
        </v-layout>
        <v-layout row wrap>
            <v-flex xs3>
                <base-facets v-on:updateFacets="updateFiltersQuery" :facets="facets"/>
            </v-flex>
            <v-flex xs9>
                <v-card>
                    <v-progress-circular
                            v-if="loading"
                            :size="50"
                            indeterminate color="primary"
                    >
                    </v-progress-circular>
                    <v-data-table
                            :headers="headers"
                            :loading="loading"
                            :items="items"
                            :options.sync="pagination"
                            :no-data-text="noDataMessage"
                            hide-default-footer
                            :items-per-page=30
                    >
                        <template v-if="!loading" v-slot:body="{items}" >
                          <tbody>
                            <tr v-for="(item, i) in items" v-bind:key="i"
                                 @click="showItem(item._source)"
                                 :class="
                                   item._source['isBlockedForLcca'] === true
                                    ? 'red white--text'
                                    : ''"
                                 :title="(item._source['isBlockedForLcca'] === true)
                                   && $i18n.t('Blocked customer LCCA')"
                            >
                              <td class="text-xs-left"
                                  v-for="(field, indexfield) in headers" v-bind:key="indexfield"
                              >
                                <p
                                  v-if="   field.value !== 'office'
                                                && field.value !== 'isBlockedForLcca'
                                                && field.value !== 'logoPath'
                                                && field.value !== 'lreContextType'
                                                && field.value !== 'salesman'"
                                >
                                  {{ item._source[field.value] }}
                                </p>
                                <p v-if="field.value === 'office' ">
                                  {{ item._source[field.value].name }}</p>
                                <p v-if="field.value === 'isBlockedForLcca' ">
                                  {{ item._source[field.value] === true ?
                                  'oui' : 'non' }}
                                </p>
                                <p v-if="field.value === 'salesman'">
                                  {{ item._source[field.value] | concatSalesmanName }}
                                </p>
                                <p v-if="field.value === 'lreContextType'">
                                  {{$t( item._source[field.value]) }}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </template>
<!--                        <v-alert slot="no-results" :value="true" color="error"-->
<!--                                 icon="warning" v-if="!loading">-->
<!--                            Votre recherche pour "{{ search }}" ne trouve aucun résultat.-->
<!--                        </v-alert>-->
                    </v-data-table>
                    <div class="text-xs-center pt-2 clients-pagination">
                        <v-pagination v-model="page" :total-visible="7" :length="pages">
                        </v-pagination>
                    </div>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import { SEARCH_DEBOUNCE_WAIT_TIMER } from '@/utils/constants';
import jwtDecode from 'jwt-decode';
import { DEFAULT_CUSTOMER_HEADER } from '../models/customerHeader';

const DEFAULT_ROWS_PER_PAGE = 15;

export default {
  name: 'BaseCustomerList',
  props: {
    title: null,
  },
  data() {
    return {
      search: '',
      headers: DEFAULT_CUSTOMER_HEADER,
      page: 1,
      pagination: {
        sortBy: [], descending: null, rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      },
      query: {
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        order: {},
        filters: {},
      },
      lastFilterHash: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.customer.loading,
      pages: (state) => state.customer.pagination.pages,
      items: (state) => state.customer.items,
      noDataMessage: (state) => state.customer.noDataMessage,
      total: (state) => state.customer.total,
      stateQuery: (state) => state.customer.query,
      facets: (state) => state.customer.facets,
      userInfo: (state) => state.user.oidcUser,
    }),
    pageTitle() {
      return this.loading ? `${this.title}` : `${this.title} (${this.total})`;
    },
    isAdminforIndexing() {
      return (jwtDecode(this.userInfo.access_token).resource_access.herculefront.roles).some((item) => item === 'ADMIN-SUPPORT');
    },
  },
  watch: {
    pagination: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    page: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    advancedFilters: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    stateFilter: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
    classificationFilter: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'customer/getCustomers', // -> this['order/getOrders']()
      'customer/updateIndexingCustomer', // -> this['order/getOrders']()
    ]),
    buildQuery() {
      this.query.page = this.page;
      this.query.sortBy = [];

      if (this.pagination.sortBy) {
        this.query.order[this.pagination.sortBy] = this.pagination.descending ? 'asc' : 'desc';
      }
    },
    delaySearch: debounce(function search() {
      this.buildQuery();
      this['customer/getCustomers'](this.query);
    }, SEARCH_DEBOUNCE_WAIT_TIMER),
    showItem(item) {
      this.$router.push({ name: 'PageClientContactShow', params: { id: item.id } });
    },
    updateIndexing() {
      this['customer/updateIndexingCustomer']();
    },
    updateFiltersQuery(values) {
      if (values instanceof Object) {
        this.query.filters = values;
      } else {
        this.query.string = values;
      }
      this.query.page = 1;
      this.page = 1;
      this.delaySearch();
    },
  },
  filters: {
    logoPath(value) {
      if (value === '' || typeof value === 'undefined' || value === null) {
        return null;
      }
      const path = `${process.env.VUE_APP_LOGO_BASE_URL}${value}`;
      const img = new Image();
      img.src = path;
      return img.height === 0 ? null : path;
    },
  },
  created() {
    this.delaySearch();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .table-filters {
        background-color: #37474f;
        border-radius: 10px;
        margin-top: 12px;
        margin-bottom: 24px;
        padding: 0 20px;
        color: #FFFF;
    }

    .ticket-list {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .clients-pagination .v-pagination{
        margin-bottom: 20px;
    }
    .theme--light.v-sheet {
        padding: 10px;
    }
    .v-card__title--primary {
      padding-bottom: 5px;
    }
    tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, .05);
    }
    @media (max-width: 959px){
        .clients-pagination{
            overflow-y: hidden;
            padding-left: 10px;
            padding-right: 10px;
        }
        .clients-pagination .v-pagination{
            overflow-y: scroll;
        }
    }
    .legend {
      list-style: none;
    }
    .legend li {
      float: left;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
    .legend span {
      border: 1px solid #ccc;
      float: left; width: 12px;
      height: 12px;
      margin: 2px;
    }
</style>
